<template>
  <b-card title="">
    <b-row class="justify-content-between align-items-center mx-0">
      <b-col class="align-items-center">
        <b-row class="my-1 text-right mt-2 d-flex justify-content-start">
          <b-form-group>
            <b-form-input type="text" v-model="search" placeholder="Buscar item"></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>

      <b-col class="align-items-center d-flex justify-content-end">
        <b-button 
          v-if="currentUser.is_admin"
          variant="primary" 
          @click="$router.push('/incluir-novo-colaborador')"
          class="mr-1"
        > 
          Novo usuário
       </b-button>

        <b-button
          v-if="currentUser.is_admin"
          variant="primary"
          @click="$router.push(`/link-user-workspace/${$store.getters.currentWorkspace.id}`)"
        >
          Vincular existente
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive="sm" small :fields="fields" :items="filter()" class="text-left">
      <template v-slot:cell(actions)="{ item }">
        <ActionsSuppliers :item="item" :currentUser="currentUser" />
      </template>

      <template v-slot:cell(employee_name)="{ item }">
        {{ item.username }}
      </template>

      <template v-slot:cell(employee_mail)="{ item }">
        {{ item.email }}
      </template>
      <template v-slot:cell(deleted_at)="{ item }">
        {{ item.deleted_at ? `Desativado em: ${dateFormatter(item.deleted_at, 'dd/MM/yyyy')}` : 'Ativo' }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BFormInput, BFormGroup, BRow, BCol, BButton } from 'bootstrap-vue';
import ActionsSuppliers from './components/ActionsSuppliers.vue';
//import moment from 'moment'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BCard,
    BRow,
    BTable,
    BFormInput,
    BFormGroup,
    BCol,
    BButton,
    ActionsSuppliers
  },
  data: () => ({
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        thStyle: { width: '20%' }
      },
      {
        key: 'employee_name',
        label: 'Nome',
        sortable: true
      },
      {
        key: 'employee_mail',
        label: 'Email',
        sortable: true
      },
      {
        key: 'deleted_at',
        label: 'Status',
        sortable: true
      }
    ],
    items: [],
    search: '',
    taxRecordsList: false
  }),
  created() {
    this.getAll();
  },

  computed: {
    currentUser() {
      return this.$store.getters.user.accesses.find(a => a.workspace_id == this.$store.getters.currentWorkspace.id);
    }
  },

  methods: {
    async getAll() {
      if (!this.userList) {
        this.$store
          .dispatch('getAllUsers', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            inactive: true
          })
          .then(resp => {
            if (resp) {
              this.items = resp;
              console.log(resp);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },

    filter() {
      if (this.search.trim() === '') {
        // If the search input is empty, return all items
        return this.items;
      } else {
        // If there's a search term, filter the items
        return this.items.filter(item => {
          return (
            item.username.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            item.email.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },

    handleUpdateList() {
      this.getAll();
    }
  }
};
</script>
