<template>
  <div :style="{ display: 'flex', gap: '16px' }">
    <feather-icon v-if="currentUser.is_admin"
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar informações de colaborador"
      icon="EditIcon"
      size="15"
       style="cursor: pointer;"
      @click="$router.push(`/editar-colaborador/${item.member_id}`)"
    />

    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Dashboard"
      icon="BarChartIcon"
      size="15"
      class="mr-1"
      style="cursor: pointer;"
      @click="$router.push(`/usuarios/dashboard/${item.member_id}`)"
    />
    <!-- <feather-icon v-b-tooltip.hover.lefttop.v-primary title="Excluir fornecedor"
    icon="DeleteIcon" size="15" class="ml-1" item:=":this.item"
    @click="checkSupplier" /> -->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
//import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'actions-supplier',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
    currentUser: {type: Object, default: () => {}}
  },
  data: () => ({
    member_info: {},
  }),
  created() {
    console.log('vem com props',this.currentUser)
  },
  methods: {
  

  },
};
</script>
